import baSummary from "data/json/ba-summary.json";
import baFeatures from "data/json/ba-features.json";
import baSentimentData from "data/json/ba-sentiment-examples.json";
import baSentimentCounts from "data/json/ba-sentiment-counts.json";
import baAllSentiment from "data/json/ba-all-sentiment.json";
import baProfanity from "data/json/ba-profanity.json";
import baCommonPhrases from "data/json/ba-common-phrases.json";
import baQuizData from "data/json/ba-quiz.json";
import { CaptionWrapper, ColoredSpan, HorizontalBarGraph, MultiBarGraph, NarrowContainer, PodcastAllSentiments, Quiz, SelectableMultiBarGraph, SentimentScoreTable, StyledTable } from "story_components";
import { SliderProvider } from "providers";
import { defaultSentimentOptions, generateTooltipData } from "data/beautiful-analysis.js";
import { colorMap } from "data/beautiful-analysis.js";
import COLORS from "utils/styles";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  baSummary,
  baFeatures,
  baSentimentData,
  baSentimentCounts,
  baAllSentiment,
  baProfanity,
  baCommonPhrases,
  baQuizData,
  CaptionWrapper,
  ColoredSpan,
  HorizontalBarGraph,
  MultiBarGraph,
  NarrowContainer,
  PodcastAllSentiments,
  Quiz,
  SelectableMultiBarGraph,
  SentimentScoreTable,
  StyledTable,
  SliderProvider,
  defaultSentimentOptions,
  generateTooltipData,
  colorMap,
  COLORS,
  React,
  MDXTag
};