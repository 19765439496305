module.exports = [{
      plugin: require('/Users/matthewlane/Dev/personal-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/matthewlane/Dev/personal-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-110074252-1","anonymize":true},
    },{
      plugin: require('/Users/matthewlane/Dev/personal-site/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1080,"showCaptions":true}}]},
    },{
      plugin: require('/Users/matthewlane/Dev/personal-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
