import { CoinFlipHistogram, CoinFlipBayesianModel, CoinFlipTable, RentDivision } from "story_components";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  CoinFlipHistogram,
  CoinFlipBayesianModel,
  CoinFlipTable,
  RentDivision,
  React,
  MDXTag
};