import { DistanceExplorer, FunctionDistanceExplorer, ManhattanCircle, ManhattanPaths, PAdicCalculator, PAdicFractalDistance, PAdicHeatChart, Sidebar, StringDistanceExplorer } from "story_components";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  DistanceExplorer,
  FunctionDistanceExplorer,
  ManhattanCircle,
  ManhattanPaths,
  PAdicCalculator,
  PAdicFractalDistance,
  PAdicHeatChart,
  Sidebar,
  StringDistanceExplorer,
  React,
  MDXTag
};