import { EfficiencyGapTable, GerrymanderHistoricalMap, IsoperimetricExplorer, ResponsiveIFrame, SampleGerrymander } from "story_components";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  EfficiencyGapTable,
  GerrymanderHistoricalMap,
  IsoperimetricExplorer,
  ResponsiveIFrame,
  SampleGerrymander,
  React,
  MDXTag
};