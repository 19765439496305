import { ResponsiveIFrame, GamingRelationships, Sidebar, Latex } from "story_components";
import { linearVisData } from "data/gaming-linear-relationships.js";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  ResponsiveIFrame,
  GamingRelationships,
  Sidebar,
  Latex,
  linearVisData,
  React,
  MDXTag
};