import { VotingBarChart, VotingLineChart, VotingMap, VotingPollWorkerAge, VotingTable } from "story_components";
import * as d from "data/strength-in-numbers.js";
import COLORS from "utils/styles";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  VotingBarChart,
  VotingLineChart,
  VotingMap,
  VotingPollWorkerAge,
  VotingTable,
  d,
  COLORS,
  React,
  MDXTag
};