import { GamingRelationships, Sidebar, Latex } from "story_components";
import { nonlinearVisData } from "data/gaming-nonlinear-relationships.js";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  GamingRelationships,
  Sidebar,
  Latex,
  nonlinearVisData,
  React,
  MDXTag
};