// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/matthewlane/Dev/personal-site/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/Users/matthewlane/Dev/personal-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/matthewlane/Dev/personal-site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/Users/matthewlane/Dev/personal-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("/Users/matthewlane/Dev/personal-site/src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-terms-js": () => import("/Users/matthewlane/Dev/personal-site/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-articles-four-weddings-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/four-weddings.mdx" /* webpackChunkName: "component---src-pages-articles-four-weddings-mdx" */),
  "component---src-pages-articles-gaming-relationships-nonlinear-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/gaming-relationships-nonlinear.mdx" /* webpackChunkName: "component---src-pages-articles-gaming-relationships-nonlinear-mdx" */),
  "component---src-pages-articles-harvesting-wins-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/harvesting-wins.mdx" /* webpackChunkName: "component---src-pages-articles-harvesting-wins-mdx" */),
  "component---src-pages-articles-income-inequality-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/income-inequality.mdx" /* webpackChunkName: "component---src-pages-articles-income-inequality-mdx" */),
  "component---src-pages-articles-dishing-on-petrie-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/dishing-on-petrie.mdx" /* webpackChunkName: "component---src-pages-articles-dishing-on-petrie-mdx" */),
  "component---src-pages-articles-gaming-relationships-linear-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/gaming-relationships-linear.mdx" /* webpackChunkName: "component---src-pages-articles-gaming-relationships-linear-mdx" */),
  "component---src-pages-articles-beautiful-analysis-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/beautiful-analysis.mdx" /* webpackChunkName: "component---src-pages-articles-beautiful-analysis-mdx" */),
  "component---src-pages-articles-strength-in-numbers-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/strength-in-numbers.mdx" /* webpackChunkName: "component---src-pages-articles-strength-in-numbers-mdx" */),
  "component---src-pages-articles-mind-the-gerrymandered-gap-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/mind-the-gerrymandered-gap.mdx" /* webpackChunkName: "component---src-pages-articles-mind-the-gerrymandered-gap-mdx" */),
  "component---src-pages-articles-keeping-distances-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/keeping-distances.mdx" /* webpackChunkName: "component---src-pages-articles-keeping-distances-mdx" */),
  "component---src-pages-articles-warming-dots-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/warming-dots.mdx" /* webpackChunkName: "component---src-pages-articles-warming-dots-mdx" */),
  "component---src-pages-articles-fairest-of-them-all-mdx": () => import("/Users/matthewlane/Dev/personal-site/src/pages/articles/fairest-of-them-all.mdx" /* webpackChunkName: "component---src-pages-articles-fairest-of-them-all-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/matthewlane/Dev/personal-site/.cache/data.json")

